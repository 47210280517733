import { useContext, forwardRef } from "react";

import { MainContext } from "contexts/MainContext";
import { Link } from "react-router-dom";

import { log_event_analytics } from "dttd/functions/log_event_analytics";

// eslint-disable-next-line react/prop-types
const TrackedExtLink = forwardRef(({ to, ...rest }, ref) => {
  const mainContext = useContext(MainContext);

  return (
    <Link
      to={to}
      {...rest}
      ref={ref}
      onClick={() =>
        log_event_analytics(mainContext, "external_link", {
          location: mainContext.pathname,
          url: to,
        })
      }
    />
  );
});

export default TrackedExtLink;
