import { useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import colors from "assets/theme/base/colors";

import CloseIcon from "@mui/icons-material/Close";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";

import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Divider from "@mui/material/Divider";

import { MainContext } from "contexts/MainContext";

import { RewardListing } from "../sections";

import TrackedExtLink from "dttd/components/TrackedExtLink";
import { log_event_analytics } from "dttd/functions/log_event_analytics";

import resolveDetailStyles from "./OfferDetailStyles";

let OfferDetailDesktopPropType = PropTypes.shape({
  merchant: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  logo: PropTypes.node.isRequired,
  briefing: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  deeplink: PropTypes.string.isRequired,
  listings: PropTypes.arrayOf(RewardListing).isRequired,
});

function OfferDetailDesktop({ idx, reward, isHighlighted }) {
  const { t } = useTranslation();
  const mainContext = useContext(MainContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    log_event_analytics(mainContext, "reward_details_open", {
      location: mainContext.pathname,
      merchant: reward.merchant,
      offer_briefing: reward.briefing,
      offer_title: reward.title,
    });
    setOpen(true);
  };
  const handleClose = () => {
    log_event_analytics(mainContext, "reward_details_close", {
      location: mainContext.pathname,
      merchant: reward.merchant,
      offer_briefing: reward.briefing,
      offer_title: reward.title,
    });
    setOpen(false);
  };
  const popupStyle = {
    position: "absolute",
    top: "47%",
    left: "50%",
    minHeight: "650px",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: "24",
    p: "4",
  };

  const buttonXStyle = {
    position: "absolute",
    top: "5px",
    right: "3px",
    color: "#344767",
  };

  const highlightLogoStyle = {
    // "-moz-transform": "scale(0.8)",
    // "-webkit-transform": "scale(0.8)",
    // transform: "scale(0.8)",
    maxHeight: "280px",
    maxWidth: "360px",
  };

  const logoStyle = {
    // "-moz-transform": "scale(0.5)",
    // "-webkit-transform": "scale(0.5)",
    // transform: "scale(0.5)",
    maxHeight: "249px",
    maxWidth: "320px",
  };

  // const logoSmallStyle = {
  //   "-moz-transform": "scale(0.35)",
  //   "-webkit-transform": "scale(0.35)",
  //   transform: "scale(0.35)",
  //   maxHeight: "279px",
  // };

  const detailPanelMaxHeight = "525px";
  const lineBreakStyle = {
    position: "absolute",
    width: "1px",
    height: detailPanelMaxHeight,
    left: "calc(41.67% - 1px/2 + 0.5px)",
    top: "40px",
    opacity: "0.8",

    /* Text/Light#2 */
    background: "#DEDEDE",
  };

  const bottomPanelStyle = {
    position: "absolute",
    width: "100%",
    height: "50px",
    left: "0px",
    bottom: "0px",
    marginBottom: "25px",
    opacity: "0.8",
  };

  return (
    <>
      <Fragment>
        {/* Highlighted View for Grand Prize */}
        {isHighlighted && (
          <Grid container item lg={12} justifyContent="center" alignItems="center" height={350}>
            <Stack direction={idx % 2 === 0 ? "row" : "row-reverse"} lg={12} spacing={1}>
              <MKBox>
                <img
                  src={reward.image}
                  alt={t(reward.briefing)}
                  width="565px"
                  height="424px"
                  // display="block"
                  style={{ borderRadius: "8px" }}
                />
              </MKBox>
              <MKBox pt={12}>
                <Grid container justifyContent="center" mx="auto" textAlign="center">
                  <Grid item lg={12} mb={1}>
                    <img
                      src={reward.logo}
                      alt={t(reward.title)}
                      width="auto"
                      display="block"
                      style={highlightLogoStyle}
                    />
                  </Grid>
                  <Grid item lg={12} mb={1}>
                    <MKTypography variant="body3" mb={1}>
                      <strong>{t(reward.briefing)}</strong>
                    </MKTypography>
                  </Grid>
                  <Grid item lg={12} mb={1}>
                    <MKButton
                      variant="gradient"
                      sx={{
                        mx: "auto",
                        color: colors.dttdDark.main,
                        display: "relative",
                        left: "0%",
                        width: "304px",
                        height: "48px",
                        background: `linear-gradient(126.21deg, ${colors.gradients.dttd.main} 6.23%, ${colors.gradients.dttd.state} 100%)`,
                        borderRadius: "400px",
                      }}
                      onClick={handleOpen}
                    >
                      <MKTypography variant="button" fontWeight="bold">
                        {t("offers.button")}
                      </MKTypography>
                    </MKButton>
                  </Grid>
                </Grid>
              </MKBox>
            </Stack>
          </Grid>
        )}

        {/* Highlighted View for Grand Prize */}
        {!isHighlighted && (
          <Card sx={{ borderRadius: "20px", minHeight: "150px" }}>
            <Grid container justifyContent="center" alignItems="center" m={2}>
              {/* <Stack direction="column" spacing={1} justifyContent="center" alignItems="center"> */}
              {/* <Grid
                item
                lg={12}
                justifyContent="center"
                alignItems="center"
                sx={{ mx: "auto", backgroundImage: `url(${reward.image})` }}
              >
                {/* <img
                  src={reward.image}
                  alt={t(reward.briefing)}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                  //height="279px"
                  // display="block"
                />
              </Grid> */}
              <Grid
                item
                container
                lg={12}
                justifyContent="center"
                alignItems="center"
                sx={{ minHeight: "80px" }}
              >
                <img
                  src={reward.logo}
                  alt={t(reward.title)}
                  //style={logoSmallStyle}
                  style={{ maxHeight: "80px", width: "50%" }}
                />
              </Grid>
              {/* <MKTypography
                  variant="caption"
                  mb={1}
                  sx={{
                    minHeight: "40px",
                    maxHeight: "80px",
                    maxWidth: "80%",
                  }}
                >
                  <strong>{t(reward.briefing)}</strong>
                </MKTypography> */}
              <Grid item lg={12} justifyContent="center" alignItems="center">
                <MKTypography variant="button" onClick={handleOpen}>
                  <MKTypography variant="button" fontWeight="bold">
                    <span>{t("offers.button")}</span>
                    <span>&gt;</span>
                  </MKTypography>
                </MKTypography>
              </Grid>
              {/* </Stack> */}
            </Grid>
          </Card>
        )}
      </Fragment>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Card sx={popupStyle}>
            <MKButton variant="text" color="black" onClick={handleClose} sx={buttonXStyle}>
              <CloseIcon />
            </MKButton>
            <MKBox component="section" py={2} style={lineBreakStyle} />
            <MKBox component="section" py={2} style={bottomPanelStyle}>
              <Grid container justifyContent="center" mx="auto" textAlign="center">
                <MKButton
                  variant="gradient"
                  sx={{
                    mx: "auto",
                    color: colors.dttdDark.main,
                    width: "304px",
                    height: "48px",
                    background: `linear-gradient(126.21deg, ${colors.gradients.dttd.main} 6.23%, ${colors.gradients.dttd.state} 100%)`,
                    borderRadius: "400px",
                  }}
                >
                  <MKTypography
                    variant="button"
                    fontWeight="bold"
                    component={Link}
                    to="/unlock-rewards"
                  >
                    {t("offers.getrewardsnow")}
                  </MKTypography>
                </MKButton>
              </Grid>
            </MKBox>
            <Grid container justifyContent="flex">
              <Grid item lg={5}>
                <MKBox
                  mt={4}
                  mx={4}
                  sx={{ maxHeight: detailPanelMaxHeight, overflow: "auto", paddingRight: "1px" }}
                >
                  <img
                    src={reward.logo}
                    alt={t(reward.title)}
                    width="auto"
                    display="block"
                    style={logoStyle}
                  />
                  <MKTypography id="transition-modal-title" variant="h6" component="h2">
                    {t(reward.title)}
                  </MKTypography>
                  <MKTypography
                    id="transition-modal-description"
                    variant="captionxs"
                    sx={{ mt: 2, display: "block" }}
                  >
                    <Trans
                      i18nKey={reward.description}
                      components={{
                        br: <br />,
                      }}
                    />
                  </MKTypography>
                  <MKTypography
                    variant="captionxs"
                    fontWeight="regular"
                    color={colors.dttdFooterText}
                    sx={{ mt: 2, textDecoration: "underline", display: "block" }}
                  >
                    <MKTypography
                      component={TrackedExtLink}
                      to={reward.route}
                      target="_blank"
                      variant="captionxs"
                      fontWeight="medium"
                      color={colors.dttdFooterText}
                    >
                      <Stack direction="row" spacing={1}>
                        <span>{reward.route}</span>
                        <OpenInBrowserIcon />
                      </Stack>
                    </MKTypography>
                  </MKTypography>
                </MKBox>
              </Grid>
              {/* <Grid item lg={1} mt={4}>
                <Divider orientation="vertical" flexItem />
              </Grid> */}
              <Grid item lg={7}>
                <MKBox
                  mt={4}
                  sx={{ maxHeight: detailPanelMaxHeight, overflow: "auto", paddingRight: "1px" }}
                >
                  <Grid container justifyContent="center" alignItems="center" textAlign="center">
                    {reward.listings.map((listing, key) => (
                      <Grid item key={key} lg={5} m={1}>
                        <MKBox p={1} style={resolveDetailStyles(listing.prizeType)}>
                          <img
                            src={listing.image}
                            width="100%"
                            alt={t(listing.description)}
                            style={{ borderRadius: "6px" }}
                          />
                          <MKTypography id="transition-modal-title" variant="h6" component="h2">
                            {t(`rewards.type.${listing.prizeType}`)}
                          </MKTypography>
                          <MKTypography variant="captionxs" sx={{ mt: 1, mb: 1, display: "block" }}>
                            <Trans
                              i18nKey={listing.description}
                              components={{
                                br: <br />,
                              }}
                            />
                          </MKTypography>
                        </MKBox>
                      </Grid>
                    ))}
                  </Grid>
                </MKBox>
              </Grid>
            </Grid>
          </Card>
        </Fade>
      </Modal>
      {/* </Card> */}
    </>
  );
}

OfferDetailDesktop.propTypes = {
  idx: PropTypes.number,
  reward: OfferDetailDesktopPropType,
  isHighlighted: PropTypes.bool,
};

export default OfferDetailDesktop;
