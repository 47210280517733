export class Reward {
  constructor(merchant, image, logo, briefing, title, description, route, deeplink, listings) {
    this.merchant = merchant;
    this.image = image;
    this.logo = logo;
    this.briefing = briefing;
    this.title = title;
    this.description = description;
    this.route = route;
    this.deeplink = deeplink;
    this.listings = listings;
  }
}

export class RewardListing {
  constructor(prizeType, image, description) {
    // prizeType: grand, top, jumbo, general
    this.prizeType = prizeType;
    this.image = image;
    this.description = description;
  }
}
