import { useState, useContext, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

//import colors from "assets/theme/base/colors";

import CloseIcon from "@mui/icons-material/Close";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Divider from "@mui/material/Divider";

import { MainContext } from "contexts/MainContext";

//import TrackedExtLink from "dttd/components/TrackedExtLink";
import { log_event_analytics } from "dttd/functions/log_event_analytics";

let PerkDetailPropType = PropTypes.shape({
  merchant: PropTypes.string.isRequired,
  logoSmall: PropTypes.node.isRequired,
  perkImage: PropTypes.node.isRequired,
  briefTitle: PropTypes.string.isRequired,
  detailTitle: PropTypes.string.isRequired,
  detailDescription: PropTypes.string.isRequired,
  soldout: PropTypes.bool.isRequired,
});

function PerkDetail({ idx, perk }) {
  const { t } = useTranslation();
  const mainContext = useContext(MainContext);
  const [open, setOpen] = useState(false);
  const startTime = useRef(new Date());
  const endTime = useRef(new Date());

  const handleOpen = () => {
    startTime.current = new Date();
    log_event_analytics(mainContext, "perk_reveal", {
      location: mainContext.pathname,
      persona_id: mainContext.persona_id,
      merchant: perk.merchant,
      perk_title: t(perk.detailTitle),
    });
    setOpen(true);
  };
  const handleClose = () => {
    endTime.current = new Date();
    const elapsedTime = (endTime.current - startTime.current) / 1000; // get the seconds
    log_event_analytics(mainContext, "perk_close_reveal", {
      location: mainContext.pathname,
      persona_id: mainContext.persona_id,
      merchant: perk.merchant,
      perk_title: t(perk.detailTitle),
      elapsed_time: elapsedTime,
    });
    setOpen(false);
  };
  const popupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    minHeight: "300px",
    maxHeight: "550px",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: "16",
    p: "1",
  };

  const buttonXStyle = {
    position: "absolute",
    top: "5px",
    right: "3px",
    color: "#344767",
  };

  // const logoStyle = {
  //   "-moz-transform": "scale(0.5)",
  //   "-webkit-transform": "scale(0.5)",
  //   transform: "scale(0.5)",
  // };

  // const logoSmallStyle = {
  //   "-moz-transform": "scale(0.35)",
  //   "-webkit-transform": "scale(0.35)",
  //   transform: "scale(0.35)",
  //   maxHeight: "279px",
  // };

  const detailPanelMaxHeight = "240px";

  return (
    <>
      <Fragment>
        <Card key={`perk-detail-${idx}`} sx={{ borderRadius: "8px", m: "3px" }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={0.2}
            sx={{ minHeight: "84px" }}
          >
            <Grid item xs={2} container justifyContent="center">
              <img src={perk.logoSmall} alt={t(perk.briefTitle)} width="69px" />
            </Grid>
            <Grid item xs={6} justifyContent="center">
              <MKTypography variant="caption" fontWeight="bold">
                {t(perk.briefTitle)}
              </MKTypography>
            </Grid>
            <Grid item xs={3}>
              {!perk.soldout ? (
                <MKTypography
                  variant="button"
                  onClick={handleOpen}
                  sx={{
                    px: "10px",
                    py: "2px",
                    background: "linear-gradient(126.21deg, #03CAFC 6.23%, #70FC8F 100%)",
                    borderRadius: "400px",
                  }}
                >
                  <MKTypography variant="button" fontWeight="bold">
                    <span>{t("perks.button")}</span>
                  </MKTypography>
                </MKTypography>
              ) : (
                <MKTypography
                  variant="button"
                  sx={{ px: "10px", py: "2px", background: "#DEDEDE", borderRadius: "8px" }}
                >
                  <MKTypography variant="button" sx={{ color: "#737C78" }}>
                    <span>{t("perks.soldout")}</span>
                  </MKTypography>
                </MKTypography>
              )}
            </Grid>
          </Grid>
        </Card>
      </Fragment>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Card sx={popupStyle}>
            <MKButton variant="text" color="black" onClick={handleClose} sx={buttonXStyle}>
              <CloseIcon />
            </MKButton>
            <Grid container justifyContent="flex" alignContent="center" textAlign="center">
              <Grid item xs={12} container justifyContent="center" alignContent="center">
                <img
                  src={perk.perkImage}
                  alt={t(perk.detailTitle)}
                  width="auto"
                  display="block"
                  style={{ maxWidth: "295px", marginTop: "20px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <MKBox mt={4} mx={4} sx={{ maxHeight: detailPanelMaxHeight, overflow: "auto" }}>
                  <MKTypography id="transition-modal-title" variant="h6" component="h2">
                    {t(perk.detailTitle)}
                  </MKTypography>
                  <MKTypography
                    id="transition-modal-description"
                    variant="captionxs"
                    sx={{
                      mt: 2,
                      display: "block",
                      textAlign: "left",
                      ul: { "list-style": "none" },
                      "ul li:before": { content: '"•"', "margin-right": "10px" },
                    }}
                  >
                    <Trans
                      i18nKey={perk.detailDescription}
                      components={{
                        hr: <hr />,
                        br: <br />,
                        strong: <strong />,
                        u: <u />,
                        ul: <ul />,
                        li: <li />,
                      }}
                    />
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12}>
                <span>&nbsp;</span>
              </Grid>
            </Grid>
          </Card>
        </Fade>
      </Modal>
      {/* </Card> */}
    </>
  );
}

PerkDetail.propTypes = {
  idx: PropTypes.number,
  perk: PerkDetailPropType,
};

export default PerkDetail;
