/* eslint-disable no-unused-vars */
import { useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import colors from "assets/theme/base/colors";

import CloseIcon from "@mui/icons-material/Close";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";

import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Divider from "@mui/material/Divider";

import { MainContext } from "contexts/MainContext";

import { RewardListing } from "../sections";

import TrackedExtLink from "dttd/components/TrackedExtLink";
import { log_event_analytics } from "dttd/functions/log_event_analytics";

import { resolveDetailMobileStyle } from "./OfferDetailStyles";

let OfferDetailMobilePropType = PropTypes.shape({
  merchant: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  logo: PropTypes.node.isRequired,
  briefing: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  deeplink: PropTypes.string.isRequired,
  listings: PropTypes.arrayOf(RewardListing).isRequired,
});

function OfferDetailMobile({ idx, reward, isHighlighted }) {
  const { t } = useTranslation();
  const mainContext = useContext(MainContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    log_event_analytics(mainContext, "reward_details_open", {
      location: mainContext.pathname,
      merchant: reward.merchant,
      offer_briefing: reward.briefing,
      offer_title: reward.title,
    });
    setOpen(true);
  };
  const handleClose = () => {
    log_event_analytics(mainContext, "reward_details_close", {
      location: mainContext.pathname,
      merchant: reward.merchant,
      offer_briefing: reward.briefing,
      offer_title: reward.title,
    });
    setOpen(false);
  };
  const popupStyle = {
    position: "absolute",
    top: "47%",
    left: "50%",
    minHeight: "650px",
    transform: "translate(-50%, -50%)",
    width: "86%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: "24",
    p: "2",
  };

  const buttonXStyle = {
    position: "absolute",
    top: "5px",
    right: "3px",
    color: "#344767",
  };

  const highlightLogoStyle = {
    // "-moz-transform": "scale(0.8)",
    // "-webkit-transform": "scale(0.8)",
    // transform: "scale(0.8)",
    maxHeight: "218px",
    maxWidth: "280px",
  };

  const nonHighlightLogoStyle = {
    // "-moz-transform": "scale(0.8)",
    // "-webkit-transform": "scale(0.8)",
    // transform: "scale(0.8)",
    maxHeight: "80px",
    maxWidth: "126px",
  };

  const logoStyle = {
    "-moz-transform": "scale(0.5)",
    "-webkit-transform": "scale(0.5)",
    transform: "scale(0.5)",
  };

  // const logoSmallStyle = {
  //   "-moz-transform": "scale(0.35)",
  //   "-webkit-transform": "scale(0.35)",
  //   transform: "scale(0.35)",
  //   maxHeight: "279px",
  // };

  const detailPanelMaxHeight = "320px";
  const lineBreakStyle = {
    position: "absolute",
    width: "1px",
    height: detailPanelMaxHeight,
    left: "calc(41.67% - 1px/2 + 0.5px)",
    top: "40px",
    opacity: "0.8",

    /* Text/Light#2 */
    background: "#DEDEDE",
  };

  const bottomPanelStyle = {
    position: "absolute",
    width: "100%",
    height: "50px",
    left: "0px",
    bottom: "0px",
    marginBottom: "25px",
    opacity: "0.8",
  };

  return (
    <>
      <Fragment>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          key={`offer-detail-m${idx}`}
          my={2}
        >
          <Card sx={{ width: "335px", minHeight: "150px", maxHeight: "580px" }}>
            <Grid container justifyContent="center" mx="auto" textAlign="center" mt={1}>
              {isHighlighted && (
                <Grid item xs={12} mt={1}>
                  <img
                    src={reward.image}
                    alt={t(reward.briefing)}
                    width="295px"
                    height="221px"
                    style={{ borderRadius: "8px" }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <img
                  src={reward.logo}
                  alt={t(reward.title)}
                  width="auto"
                  display="block"
                  //style={{ maxWidth: "126px", maxHeight: "80px" }}
                  style={isHighlighted ? highlightLogoStyle : nonHighlightLogoStyle}
                />
              </Grid>
              {isHighlighted && (
                <Grid item xs={12}>
                  <MKTypography variant="body3">
                    <strong>{t(reward.briefing)}</strong>
                  </MKTypography>
                </Grid>
              )}
              <Grid item xs={12}>
                <MKButton variant="button" onClick={handleOpen}>
                  <MKTypography variant="caption" fontWeight="bold">
                    {t("offers.button")}
                  </MKTypography>
                </MKButton>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* Highlighted View for Grand Prize */}
        {/* {!isHighlighted && (
          <Card sx={{ borderRadius: "20px" }}>
            <Grid container justifyContent="center" alignItems="center" m={2}>
              <Stack direction="column" spacing={1}>
                <img
                  src={reward.image}
                  alt={t(reward.briefing)}
                  width="372px"
                  height="279px"
                  // display="block"
                />
                <img
                  src={reward.logo}
                  alt={t(reward.title)}
                  //style={logoSmallStyle}
                  style={{ maxHeight: "80px", width: "auto" }}
                />
                <MKTypography variant="caption" mb={1}>
                  <strong>{t(reward.briefing)}</strong>
                </MKTypography>
                <MKTypography variant="button" onClick={handleOpen}>
                  <MKTypography variant="button" fontWeight="bold">
                    <span>{t("offers.button")}</span>
                    <span>&gt;</span>
                  </MKTypography>
                </MKTypography>
              </Stack>
            </Grid>
          </Card>
        )} */}
      </Fragment>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Card sx={popupStyle}>
            <MKButton variant="text" color="black" onClick={handleClose} sx={buttonXStyle}>
              <CloseIcon />
            </MKButton>
            <Grid container justifyContent="flex">
              <Grid item xs={12}>
                <MKBox
                  mt={4}
                  mx={4}
                  sx={{ maxHeight: detailPanelMaxHeight, overflow: "auto", paddingRight: "1px" }}
                >
                  <img
                    src={reward.logo}
                    alt={t(reward.title)}
                    //style={logoStyle}
                    style={{ maxHeight: "140px", maxWidth: "220px" }}
                  />
                  <MKTypography id="transition-modal-title" variant="h6" component="h2">
                    {t(reward.title)}
                  </MKTypography>
                  <MKTypography
                    id="transition-modal-description"
                    variant="captionxs"
                    sx={{ mt: 2, display: "block" }}
                  >
                    <Trans
                      i18nKey={reward.description}
                      components={{
                        br: <br />,
                      }}
                    />
                  </MKTypography>
                  <MKTypography
                    variant="captionxs"
                    fontWeight="regular"
                    color={colors.dttdFooterText}
                    sx={{ mt: 2, textDecoration: "underline", display: "block" }}
                  >
                    <MKTypography
                      component={TrackedExtLink}
                      to={reward.route}
                      target="_blank"
                      variant="captionxs"
                      fontWeight="medium"
                      color={colors.dttdFooterText}
                    >
                      <Stack direction="row" spacing={1}>
                        <span>{reward.route}</span>
                        <OpenInBrowserIcon />
                      </Stack>
                    </MKTypography>
                  </MKTypography>
                </MKBox>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" mx="auto" textAlign="center" mb={9}>
              <Grid item xs={12}>
                <MKBox
                  mt={4}
                  sx={{ maxHeight: detailPanelMaxHeight, overflow: "auto", paddingRight: "1px" }}
                >
                  <Grid container justifyContent="center" alignItems="center" textAlign="center">
                    {reward.listings.map((listing, key) => (
                      <Grid item key={key} xs={12} m={1} ml={2}>
                        <MKBox p={1} sx={resolveDetailMobileStyle(listing.prizeType)}>
                          <Grid container justifyContent="center" alignItems="center">
                            <img
                              src={listing.image}
                              width="100%"
                              alt={t(listing.description)}
                              style={{ borderRadius: "6px" }}
                            />
                          </Grid>
                          <MKTypography id="transition-modal-title" variant="h6" component="h2">
                            {t(`rewards.type.${listing.prizeType}`)}
                          </MKTypography>
                          <MKTypography variant="captionxs" sx={{ mt: 1, mb: 1, display: "block" }}>
                            <Trans
                              i18nKey={listing.description}
                              components={{
                                br: <br />,
                              }}
                            />
                          </MKTypography>
                        </MKBox>
                      </Grid>
                    ))}
                  </Grid>
                </MKBox>
              </Grid>
            </Grid>
            {/* <MKBox component="section" py={2} style={lineBreakStyle} /> */}
            <MKBox component="section" py={2} style={bottomPanelStyle}>
              <Grid
                container
                justifyContent="center"
                mx="auto"
                alignContent="center"
                textAlign="center"
              >
                <MKButton
                  variant="gradient"
                  sx={{
                    mx: "auto",
                    color: colors.dttdDark.main,
                    width: "304px",
                    height: "48px",
                    background: `linear-gradient(126.21deg, ${colors.gradients.dttd.main} 6.23%, ${colors.gradients.dttd.state} 100%)`,
                    borderRadius: "400px",
                  }}
                >
                  <MKTypography
                    variant="button"
                    fontWeight="bold"
                    component={Link}
                    to="/unlock-rewards"
                  >
                    {t("offers.getrewardsnow")}
                  </MKTypography>
                </MKButton>
              </Grid>
            </MKBox>
          </Card>
        </Fade>
      </Modal>
      {/* </Card> */}
    </>
  );
}

OfferDetailMobile.propTypes = {
  idx: PropTypes.number,
  reward: OfferDetailMobilePropType,
  isHighlighted: PropTypes.bool,
};

export default OfferDetailMobile;
