/*
=========================================================
* DOTTED
=========================================================



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React pages
import UnlockRewards from "pages/UnlockRewards";

export default function UnlockRewardsPage() {
  return <UnlockRewards />;
}
