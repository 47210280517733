export const retrieve_location = (mainContext) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    try {
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            //console.log("data from ipapi.co: ", data);
            mainContext.setUserIpVersion(data.version);
            mainContext.setUserIp(data.ip);
            mainContext.setUserCountryCode(data.country_code_iso3);
            mainContext.setUserCountryName(data.country_name);
            mainContext.setUserCity(data.city);
            mainContext.setUserTimezone(data.timezone);
            mainContext.setUserLatitude(data.latitude);
            mainContext.setUserLongitude(data.longitude);
            // Flag ready
            mainContext.setUserIpReady(true);
          } else {
            // In case of any error, fallback to use ipify.org
            fetch("https://api.ipify.org/?format=json/")
              .then((response) => response.json())
              .then((data) => {
                mainContext.setUserIpVersion("IPv4");
                mainContext.setUserIp(data.ip);
                // Flag ready
                mainContext.setUserIpReady(true);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        })
        .catch((error) => {
          console.error("Error in fetching ipapi.co: ", error);
          // In case of any error, fallback to use ipify.org
          fetch("https://api.ipify.org/?format=json/")
            .then((response) => response.json())
            .then((data) => {
              mainContext.setUserIpVersion("IPv4");
              mainContext.setUserIp(data.ip);

              // Flag ready
              mainContext.setUserIpReady(true);
            })
            .catch((fb_error) => {
              console.error(fb_error);
            });
        });
    } catch (error) {
      // In case of any error, fallback to use ipify.org
      fetch("https://api.ipify.org/?format=json/")
        .then((response) => response.json())
        .then((data) => {
          mainContext.setUserIpVersion("IPv4");
          mainContext.setUserIp(data.ip);
          // Flag ready
          mainContext.setUserIpReady(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  });
};
