import { useEffect, useState } from "react";
import { Trans } from "react-i18next";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";

import breakpoints from "assets/theme/base/breakpoints";
import DefaultFooter from "dttd/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

import logoImage from "assets/images/dttd-logo-for-bg.png";
import bgImage from "assets/images/gradient_background.png";
import bgImageMobile from "assets/images/bg-next-gradient-mobile.png";
import MKTypography from "components/MKTypography";

function TermsConditions() {
  const [mobileView, setMobileView] = useState(false);

  const contestBgStyle = {
    width: "100%",
    // background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${bgImage})`,
    //background: "linear-gradient(287.56deg, #F4FEFA 0%, rgba(244, 254, 250, 0) 100%)",
    //background: "rgba(255, 255, 255, 1)",
    background: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    display: "grid",
    placeItems: "top",
    // Mobile: Landscape
    "@media only screen and (max-width: 390px)": {
      //background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${bgImageMobile})`,
      //background: "rgba(255, 255, 255, 1)",
      background: `url(${bgImageMobile})`,
      backgroundSize: "cover",
      backgroundPosition: "top",
      display: "grid",
      placeItems: "top",
      position: "sticky",
    },
  };

  useEffect(() => {
    // A function that sets the display state for Mobile view or not
    function displayMobileView() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileView);

    // Call the displayMobileView function to set the state with the initial value.
    displayMobileView();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileView);
  }, []);

  return (
    <>
      {/* Desktop View Layout */}
      {!mobileView && (
        <MKBox sx={contestBgStyle}>
          <Container>
            <Grid container p={2} spacing={2}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    m: 0,
                    p: 0,
                    backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                      rgba(black.main, 0.0),
                    boxShadow: "none",
                  }}
                >
                  <img src={logoImage} alt="DOTTED" width="125" height="40" />
                </Card>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={12}
                lg={12}
                alignContent={"center"}
                justifyContent={"center"}
              >
                <Card sx={{ p: "16px" }}>
                  <MKTypography>
                    <Trans
                      i18nKey={"terms&conditions1"}
                      components={{
                        br: <br />,
                      }}
                    />
                  </MKTypography>
                  <MKTypography>
                    <Trans
                      i18nKey={"terms&conditions2"}
                      components={{
                        br: <br />,
                      }}
                    />
                  </MKTypography>
                  <MKTypography>
                    <Trans
                      i18nKey={"terms&conditions3"}
                      components={{
                        br: <br />,
                      }}
                    />
                  </MKTypography>
                  <MKTypography>
                    <Trans
                      i18nKey={"terms&conditions4"}
                      components={{
                        br: <br />,
                      }}
                    />
                  </MKTypography>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <DefaultFooter content={footerRoutes} />
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      )}
      {/* Mobile View Layout */}
      {mobileView && (
        <MKBox sx={contestBgStyle}>
          <Container>
            <Grid container pt={4}>
              <Grid item xs={12} sm={12}>
                <Card
                  sx={{
                    m: 0,
                    p: 0,
                    backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                      rgba(black.main, 0.0),
                    boxShadow: "none",
                  }}
                >
                  <img src={logoImage} alt="DOTTED" width="88" height="28" />
                </Card>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Card sx={{ p: "16px" }}>
                  <MKTypography>
                    <Trans
                      i18nKey={"terms&conditions1"}
                      components={{
                        br: <br />,
                      }}
                    />
                  </MKTypography>
                  <MKTypography>
                    <Trans
                      i18nKey={"terms&conditions2"}
                      components={{
                        br: <br />,
                      }}
                    />
                  </MKTypography>
                  <MKTypography>
                    <Trans
                      i18nKey={"terms&conditions3"}
                      components={{
                        br: <br />,
                      }}
                    />
                  </MKTypography>
                  <MKTypography>
                    <Trans
                      i18nKey={"terms&conditions4"}
                      components={{
                        br: <br />,
                      }}
                    />
                  </MKTypography>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <DefaultFooter content={footerRoutes} />
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      )}
    </>
  );
}

export default TermsConditions;
