/* eslint-disable react/prop-types */

import { useContext } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Link from "@material-ui/core/Link";

import { MainContext } from "contexts/MainContext";

// react-router-dom components
import { Link } from "react-router-dom";
import TrackedExtLink from "dttd/components/TrackedExtLink";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import colors from "assets/theme/base/colors";

export const FooterDesktop = ({ content, changeLanguageHandler }) => {
  const mainContext = useContext(MainContext);
  // eslint-disable-next-line react/prop-types
  const { socials, copyright, termsConditions, privacyPolicy } = content;

  const socialBgStyle = {
    height: "24px",
    width: "24px",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    display: "inline-block",
  };

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12} mt={1} mb={5}>
          <Container>
            <Grid container spacing={1}>
              <Grid item xs={6} md={7} lg={7}>
                <MKBox display="flex" alignItems="center" color={colors.dttdFooterText}>
                  <MKTypography variant="h4" color={colors.dttdFooterText} mx={2}>
                    {copyright}
                  </MKTypography>
                  <MKTypography variant="h4" color={colors.dttdFooterText} mx={2}>
                    {termsConditions}
                  </MKTypography>
                  <MKTypography variant="h4" color={colors.dttdFooterText} mx={2}>
                    {privacyPolicy}
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={6} md={5} lg={5}>
                <Grid container justifyContent={"flex-end"}>
                  <MKBox display="flex" alignItems="center" color={colors.dttdFooterText}>
                    {socials.map(({ icon, link }, key) => (
                      <MKTypography
                        key={link}
                        component={TrackedExtLink}
                        to={link}
                        target="_blank"
                        rel="noreferrer"
                        variant="h5"
                        color={colors.dttdFooterText}
                        opacity={0.8}
                        mr={key === socials.length - 1 ? 0 : 2.5}
                        sx={socialBgStyle}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <MKBox sx={{ m: "2px" }}>{icon}</MKBox>
                      </MKTypography>
                    ))}
                    <MKTypography ml={2} />
                    <MKTypography
                      component={Link}
                      to="#"
                      rel="noreferrer"
                      variant="button"
                      color="dttdFooterText"
                      mx={1}
                    >
                      <Link onClick={() => changeLanguageHandler("tc")}>
                        <MKTypography
                          color="dttdFooterText"
                          size="sm"
                          fontWeight={mainContext.language === "tc" ? "medium" : "light"}
                          sx={{
                            fontSize: "0.75rem",
                            textDecoration: mainContext.language === "tc" ? "none" : "underline",
                          }}
                        >
                          繁
                        </MKTypography>
                      </Link>
                    </MKTypography>
                    <MKTypography color={colors.dttdFooterText}>|</MKTypography>
                    <MKTypography
                      component={Link}
                      to="#"
                      rel="noreferrer"
                      variant="button"
                      color="dttdFooterText"
                      mx={1}
                    >
                      <Link onClick={() => changeLanguageHandler("en")}>
                        <MKTypography
                          color="dttdFooterText"
                          size="sm"
                          fontWeight={mainContext.language === "en" ? "medium" : "light"}
                          sx={{
                            fontSize: "0.75rem",
                            textDecoration: mainContext.language === "en" ? "none" : "underline",
                          }}
                        >
                          ENG
                        </MKTypography>
                      </Link>
                    </MKTypography>
                    {/* <MKTypography color="white">|</MKTypography>
                    <MKTypography
                      component={Link}
                      to="#"
                      rel="noreferrer"
                      variant="button"
                      color="white"
                      mx={1}
                    >
                      <Link onClick={() => changeLanguageHandler("sc")}>
                        <MKTypography
                          color="white"
                          size="sm"
                          fontWeight={mainContext.language === "sc" ? "medium" : "light"}
                          sx={{
                            fontSize: "0.75rem",
                            textDecoration: mainContext.language === "sc" ? "none" : "underline",
                          }}
                        >
                          简
                        </MKTypography>
                      </Link>
                    </MKTypography> */}
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};
