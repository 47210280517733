import { useState, useEffect, Fragment } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// react-router-dom
import { useNavigate } from "react-router-dom";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import DefaultFooter from "dttd/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

import { unlockBgStyle } from "./unlockBgStyle";

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Import Images
import logoImage from "assets/images/dttd-logo-for-bg.png";
//import MainMobile from "./sections/UnlockRewardsMobile";
import MainDesktop from "./sections/UnlockRewardsDesktop";

function UnlockRewards() {
  const [mobileView, setMobileView] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // A function that sets the display state for Mobile view or not
    function displayMobileView() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileView);

    // Call the displayMobileView function to set the state with the initial value.
    displayMobileView();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileView);
  }, []);

  const navigateHome = () => {
    navigate("/");
  };

  return (
    <>
      <MKBox sx={unlockBgStyle}>
        <Container>
          <Grid item xs={12} md={12} lg={12} ml={2} mt={2}>
            <Card
              sx={{
                m: 0,
                p: 0,
                backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                  rgba(black.main, 0.0),
                boxShadow: "none",
              }}
            >
              <img src={logoImage} alt="DOTTED" width="125" height="40" onClick={navigateHome} />
            </Card>
          </Grid>
          <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
            <Fragment>
              {mobileView && <MainDesktop />}
              {!mobileView && <MainDesktop />}
            </Fragment>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MKBox>
              <DefaultFooter content={footerRoutes} />
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default UnlockRewards;
