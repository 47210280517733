// @mui icons
//import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
//import LanguageIcon from "@mui/icons-material/Language";
import InstagramIcon from "@mui/icons-material/Instagram";

// react-router components
//import { Link } from "react-router-dom";
import TrackedExtLink from "dttd/components/TrackedExtLink";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/dttd-logo.png";

import colors from "assets/theme/base/colors";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "DOTTED Company",
    image: logoCT,
    route: "/",
  },
  socials: [
    // {
    //   icon: <LanguageIcon />,
    //   link: "https://dttd.io",
    // },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/dttd_nft",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@dttd5659",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/dttdnft/",
    },
    {
      icon: <InstagramIcon />,
      link: "https://instagram.com/dttd_nft",
    },
  ],
  menus: [
    {
      name: "company",
      items: [{ name: "about us", href: "https://dttd.io" }],
    },
    {
      name: "get the app",
      items: [
        { name: "apple app store", href: "https://hop.dttd.app/appstoredownload" },
        { name: "google play store", href: "https://hop.dttd.app/googleplaydownload" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "https://dttd.io" },
        { name: "support", href: "https://dttd-support.freshdesk.com/support/tickets/new" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "https://www.dttd.io/terms-and-conditions" },
        { name: "privacy policy", href: "https://www.dttd.io/privacy-policy" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular" color={colors.dttdFooterText}>
      &copy; {date} by{" "}
      <MKTypography
        component={TrackedExtLink}
        to="https://www.dttd.io"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
        color={colors.dttdFooterText}
      >
        DOTTED Company Limited
      </MKTypography>
    </MKTypography>
  ),
  termsConditions: (
    <MKTypography
      variant="button"
      fontWeight="regular"
      color={colors.dttdFooterText}
      sx={{ textDecoration: "underline" }}
    >
      <MKTypography
        component={TrackedExtLink}
        to="/terms-conditions"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="medium"
        color={colors.dttdFooterText}
      >
        Terms & Conditions
      </MKTypography>
    </MKTypography>
  ),
  privacyPolicy: (
    <MKTypography
      variant="button"
      fontWeight="regular"
      color={colors.dttdFooterText}
      sx={{ textDecoration: "underline" }}
    >
      <MKTypography
        component={TrackedExtLink}
        to="/terms-conditions"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="medium"
        color={colors.dttdFooterText}
      >
        Privacy Policy
      </MKTypography>
    </MKTypography>
  ),
};
