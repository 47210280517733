// Images
import bgImage from "assets/images/gradient_background.png";
import bgImageMobile from "assets/images/bg-next-gradient-mobile.png";

// Contest Background Responsive Style (CSS in JS)
export const revealBgStyle = {
  width: "100%",
  background: `url(${bgImage})`,
  backgroundSize: "cover",
  backgroundPosition: "top",
  display: "grid",
  placeItems: "top",
  minHeight: "760px",

  // Mobile: Landscape
  "@media only screen and (max-width: 390px)": {
    background: `url(${bgImageMobile})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    display: "grid",
    placeItems: "top",
    position: "sticky",
  },
};
