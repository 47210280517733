//import { useContext } from "react";
import { useTranslation } from "react-i18next";

import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";

import TrackedClaimLink from "dttd/components/TrackedClaimLink";
//import { RegisterContext } from "contexts/RegisterContext";

import colors from "assets/theme/base/colors";

// image
import dttdInstallImage from "assets/images/dttd-install-app.png";

import step1image from "assets/images/unlock-rewards/step1.png";
import step2image from "assets/images/unlock-rewards/step2.png";
import step3image from "assets/images/unlock-rewards/step3.png";
import step4image from "assets/images/unlock-rewards/step4.png";
import step5image from "assets/images/unlock-rewards/step5.png";

function UnlockRewardsDesktop() {
  const { t } = useTranslation();

  // eslint-disable-next-line no-undef
  const registration_url = process.env.REACT_APP_REGISTRATION_URL || "http://localhost:3000";
  //const registerContext = useContext(RegisterContext);

  const steps = [
    { id: 1, title: "unlockrewards.step1", image: step1image },
    { id: 2, title: "unlockrewards.step2", image: step2image },
    { id: 3, title: "unlockrewards.step3", image: step3image },
    { id: 4, title: "unlockrewards.step4", image: step4image },
    { id: 5, title: "unlockrewards.step5", image: step5image },
  ];

  const stepsStyle = {
    display: "block",
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    backgroundColor: "#8CE8FF4D",
    pt: "8px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "19.36px",
    color: "#737C78",
  };

  return (
    <>
      <MKTypography variant="h2" align="center">
        {t("unlockrewards.title")}
      </MKTypography>
      <Grid container justifyItems="center">
        {steps.map((step) => (
          <Grid key={"unlock-rewards-" + step.id} item xs={12} lg={12}>
            <Stack
              direction="column"
              ustifyContent="center"
              justifyItems="center"
              alignItems="center"
              spacing={1}
            >
              <img src={step.image} alt={step.title} width="304px" />
              <MKBox sx={stepsStyle}>{step.id}</MKBox>
              <MKTypography variant="body3" align="center">
                {t(step.title)}
              </MKTypography>
            </Stack>
          </Grid>
        ))}
        <Grid item xs={12} lg={4} my={2} />
        <Grid item xs={12} lg={4} my={2}>
          <MKTypography variant="h3" align="center" my="24px">
            {t("unlockrewards.subtitle")}
          </MKTypography>
          <Card width="412px">
            <Stack
              direction="column"
              spacing={1}
              justifyContent="center"
              alignItems="center"
              my="20px"
            >
              <img src={dttdInstallImage} width="296px" height="138px" alt="install DOTTED app" />
              <MKButton
                variant="gradient"
                sx={{
                  mx: "10px",
                  color: colors.dttdDark.main,
                  width: "240px",
                  height: "48px",
                  background: `linear-gradient(126.21deg, ${colors.gradients.dttd.main} 6.23%, ${colors.gradients.dttd.state} 100%)`,
                  borderRadius: "400px",
                }}
              >
                <MKTypography
                  variant="button"
                  component={TrackedClaimLink}
                  to={registration_url}
                  target="_blank"
                  fontWeight="bold"
                >
                  {t("unlockrewards.button")}
                </MKTypography>
              </MKButton>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={4} lg={4} my={2} />
      </Grid>
    </>
  );
}

export default UnlockRewardsDesktop;
