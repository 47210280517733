// Reward class
import { Reward, RewardListing } from "pages/MerchantOffers/sections";

// Images - Regala
import logoRegala from "assets/images/rewards/regala/regala-logo.png";
import rewardsRegala from "assets/images/rewards/regala/regala-briefing-img.png";
import regalaListing1 from "assets/images/rewards/regala/regala-listing1.png";
import regalaListing2 from "assets/images/rewards/regala/regala-listing2.png";
import regalaListing3 from "assets/images/rewards/regala/regala-listing3.png";
import regalaListing4 from "assets/images/rewards/regala/regala-listing4.png";
import regalaListing5 from "assets/images/rewards/regala/regala-listing5.png";

// Images - Tramways
import logoTramways from "assets/images/rewards/hk-tramways/tramways-logo.png";
import rewardsTramways from "assets/images/rewards/hk-tramways/tramways-briefing-img.png";
import tramwaysListing1 from "assets/images/rewards/hk-tramways/tramways-listing1.png";

// Images - iClub
import logoIclub from "assets/images/rewards/iclub/iclub-logo.png";
import rewardsIclub from "assets/images/rewards/iclub/iclub-briefing-img.png";
import iclubListing1 from "assets/images/rewards/iclub/iclub-listing1.png";

// Images - Regal
import logoRegal from "assets/images/rewards/regal-hotel/regal-hotel-logo.png";
import rewardsRegal from "assets/images/rewards/regal-hotel/regal-hotel-briefing-img.png";
import regalListing1 from "assets/images/rewards/regal-hotel/regal-hotel-listing1.png";
import regalListing2 from "assets/images/rewards/regal-hotel/regal-hotel-listing2.png";
import regalListing3 from "assets/images/rewards/regal-hotel/regal-hotel-listing3.png";
import regalListing4 from "assets/images/rewards/regal-hotel/regal-hotel-listing4.png";
import regalListing5 from "assets/images/rewards/regal-hotel/regal-hotel-listing5.png";
import regalListing6 from "assets/images/rewards/regal-hotel/regal-hotel-listing6.png";
import regalListing7 from "assets/images/rewards/regal-hotel/regal-hotel-listing7.png";
import regalListing8 from "assets/images/rewards/regal-hotel/regal-hotel-listing8.png";
import regalListing9 from "assets/images/rewards/regal-hotel/regal-hotel-listing9.png";
import regalListing10 from "assets/images/rewards/regal-hotel/regal-hotel-listing10.png";
import regalListing11 from "assets/images/rewards/regal-hotel/regal-hotel-listing11.png";
import regalListing12 from "assets/images/rewards/regal-hotel/regal-hotel-listing12.png";
import regalListing13 from "assets/images/rewards/regal-hotel/regal-hotel-listing13.png";
import regalListing14 from "assets/images/rewards/regal-hotel/regal-hotel-listing14.png";
import regalListing15 from "assets/images/rewards/regal-hotel/regal-hotel-listing15.png";
import regalListing16 from "assets/images/rewards/regal-hotel/regal-hotel-listing16.png";
import regalListing17 from "assets/images/rewards/regal-hotel/regal-hotel-listing17.png";
import regalListing18 from "assets/images/rewards/regal-hotel/regal-hotel-listing18.png";
import regalListing19 from "assets/images/rewards/regal-hotel/regal-hotel-listing19.png";
import regalListing20 from "assets/images/rewards/regal-hotel/regal-hotel-listing20.png";
import regalListing21 from "assets/images/rewards/regal-hotel/regal-hotel-listing21.png";
import regalListing22 from "assets/images/rewards/regal-hotel/regal-hotel-listing22.png";
import regalListing23 from "assets/images/rewards/regal-hotel/regal-hotel-listing23.png";
import regalListing24 from "assets/images/rewards/regal-hotel/regal-hotel-listing24.png";
import regalListing25 from "assets/images/rewards/regal-hotel/regal-hotel-listing25.png";
import regalListing26 from "assets/images/rewards/regal-hotel/regal-hotel-listing26.png";
import regalListing27 from "assets/images/rewards/regal-hotel/regal-hotel-listing27.png";
import regalListing28 from "assets/images/rewards/regal-hotel/regal-hotel-listing28.png";
import regalListing29 from "assets/images/rewards/regal-hotel/regal-hotel-listing29.png";
import regalListing30 from "assets/images/rewards/regal-hotel/regal-hotel-listing30.png";

// Images - Amalfitana Central
import logoAmalfitanaCentral from "assets/images/rewards/amalfitana-central/amalfitana-central-logo.png";
import rewardsAmalfitanaCentral from "assets/images/rewards/amalfitana-central/amalfitana-central-briefing-img.png";
import amalfitanaCentralListing1 from "assets/images/rewards/amalfitana-central/amalfitana-central-listing1.png";
import amalfitanaCentralListing2 from "assets/images/rewards/amalfitana-central/amalfitana-central-listing2.png";

// Images - Amalfitana Repulse Bay
import logoAmalfitanaRepulseBay from "assets/images/rewards/amalfitana-repulse-bay/amalfitana-repulse-bay-logo.png";
import rewardsAmalfitanaRepulseBay from "assets/images/rewards/amalfitana-repulse-bay/amalfitana-repulse-bay-briefing-img.png";
import amalfitanaRepulseBayListing1 from "assets/images/rewards/amalfitana-repulse-bay/amalfitana-repulse-bay-listing1.png";

// Images - BRICK LANE
import logoBRICKLANE from "assets/images/rewards/brick-lane/brick-lane-logo.png";
import rewardsBRICKLANE from "assets/images/rewards/brick-lane/brick-lane-briefing-img.png";
import brickLaneListing1 from "assets/images/rewards/brick-lane/brick-lane-listing1.png";

// Images - CAMPSITE
import logoCAMPSITE from "assets/images/rewards/campsite/campsite-logo.png";
import rewardsCAMPSITE from "assets/images/rewards/campsite/campsite-briefing-img.png";
import campsiteListing1 from "assets/images/rewards/campsite/campsite-listing1.png";

// Images - Carnaby Fair
import logoCarnabyFair from "assets/images/rewards/carnaby-fair/carnaby-fair-logo.png";
import rewardsCarnabyFair from "assets/images/rewards/carnaby-fair/carnaby-fair-briefing-img.png";
import carnabyFairListing1 from "assets/images/rewards/carnaby-fair/carnaby-fair-listing1.png";

// Images - Chart Coffee
import logoChartCoffee from "assets/images/rewards/chart-coffee/chart-coffee-logo.png";
import rewardsChartCoffee from "assets/images/rewards/chart-coffee/chart-coffee-briefing-img.png";
import chartCoffeeListing1 from "assets/images/rewards/chart-coffee/chart-coffee-listing1.png";
import chartCoffeeListing2 from "assets/images/rewards/chart-coffee/chart-coffee-listing2.png";

// Images - CheckCheckCin
import logoCheckCheckCin from "assets/images/rewards/check-check-cin/check-check-cin-logo.png";
import rewardsCheckCheckCin from "assets/images/rewards/check-check-cin/check-check-cin-briefing-img.png";
import checkCheckCinListing1 from "assets/images/rewards/check-check-cin/check-check-cin-listing1.png";
import checkCheckCinListing2 from "assets/images/rewards/check-check-cin/check-check-cin-listing2.png";
import checkCheckCinListing3 from "assets/images/rewards/check-check-cin/check-check-cin-listing3.png";

// Images - Cookieism
import logoCookieism from "assets/images/rewards/cookieism/cookieism-logo.png";
import rewardsCookieism from "assets/images/rewards/cookieism/cookieism-briefing-img.png";
import cookieismListing1 from "assets/images/rewards/cookieism/cookieism-listing1.png";

// Images - Faye Hong Kong
import logoFayeHongKong from "assets/images/rewards/faye-hong-kong/faye-hong-kong-logo.png";
import rewardsFayeHongKong from "assets/images/rewards/faye-hong-kong/faye-hong-kong-briefing-img.png";
import fayeHongKongListing1 from "assets/images/rewards/faye-hong-kong/faye-hong-kong-listing1.png";

// Images - foodpandaMall
import logoFoodpandaMall from "assets/images/rewards/foodpanda-mall/foodpanda-mall-logo.png";
import rewardsFoodpandaMall from "assets/images/rewards/foodpanda-mall/foodpanda-mall-briefing-img.png";
import foodpandaMallListing1 from "assets/images/rewards/foodpanda-mall/foodpanda-mall-listing1.png";

// Images - Fratelli
import logoFratelli from "assets/images/rewards/fratelli/fratelli-logo.png";
import rewardsFratelli from "assets/images/rewards/fratelli/fratelli-briefing-img.png";
import fratelliListing1 from "assets/images/rewards/fratelli/fratelli-listing1.png";

// Images - KIBO
import logoKIBO from "assets/images/rewards/kibo/kibo-logo.png";
import rewardsKIBO from "assets/images/rewards/kibo/kibo-briefing-img.png";
import kiboListing1 from "assets/images/rewards/kibo/kibo-listing1.png";
import kiboListing2 from "assets/images/rewards/kibo/kibo-listing2.png";
import kiboListing3 from "assets/images/rewards/kibo/kibo-listing3.png";

// Images - LAB EAT
import logoLABEAT from "assets/images/rewards/lab-eat/lab-eat-logo.png";
import rewardsLABEAT from "assets/images/rewards/lab-eat/lab-eat-briefing-img.png";
import labEatListing1 from "assets/images/rewards/lab-eat/lab-eat-listing1.png";
import labEatListing2 from "assets/images/rewards/lab-eat/lab-eat-listing2.png";

// Images - LITE Salad Bar
import logoLITESaladBar from "assets/images/rewards/lite-salad-bar/lite-salad-bar-logo.png";
import rewardsLITESaladBar from "assets/images/rewards/lite-salad-bar/lite-salad-bar-briefing-img.png";
import liteSaladBarListing1 from "assets/images/rewards/lite-salad-bar/lite-salad-bar-listing1.png";
import liteSaladBarListing2 from "assets/images/rewards/lite-salad-bar/lite-salad-bar-listing2.png";

// Images - Maison Kayser
import logoMaisonKayser from "assets/images/rewards/maison-kayser/maison-kayser-logo.png";
import rewardsMaisonKayser from "assets/images/rewards/maison-kayser/maison-kayser-briefing-img.png";
import maisonKayserListing1 from "assets/images/rewards/maison-kayser/maison-kayser-listing1.png";

// Images - Mammy Pancake
import logoMammyPancake from "assets/images/rewards/mammy-pancake/mammy-pancake-logo.png";
import rewardsMammyPancake from "assets/images/rewards/mammy-pancake/mammy-pancake-briefing-img.png";
import mammyPancakeListing1 from "assets/images/rewards/mammy-pancake/mammy-pancake-listing1.png";
import mammyPancakeListing2 from "assets/images/rewards/mammy-pancake/mammy-pancake-listing2.png";

// Images - Mezzanine Makers
import logoMezzanineMakers from "assets/images/rewards/mezzanine-makers/mezzanine-makers-logo.png";
import rewardsMezzanineMakers from "assets/images/rewards/mezzanine-makers/mezzanine-makers-briefing-img.png";
import mezzanineMakersListing1 from "assets/images/rewards/mezzanine-makers/mezzanine-makers-listing1.png";
import mezzanineMakersListing2 from "assets/images/rewards/mezzanine-makers/mezzanine-makers-listing2.png";

// Images - NODI Coffee
import logoNODICoffee from "assets/images/rewards/nodi-coffee/nodi-coffee-logo.png";
import rewardsNODICoffee from "assets/images/rewards/nodi-coffee/nodi-coffee-briefing-img.png";
import nodiCoffeeListing1 from "assets/images/rewards/nodi-coffee/nodi-coffee-listing1.png";

// Images - pandamart
import logoPandamart from "assets/images/rewards/pandamart/pandamart-logo.png";
import rewardsPandamart from "assets/images/rewards/pandamart/pandamart-briefing-img.png";
import pandamartListing1 from "assets/images/rewards/pandamart/pandamart-listing1.png";

// Images - RiceOnly
import logoRiceOnly from "assets/images/rewards/riceonly/riceonly-logo.png";
import rewardsRiceOnly from "assets/images/rewards/riceonly/riceonly-briefing-img.png";
import riceOnlyListing1 from "assets/images/rewards/riceonly/riceonly-listing1.png";

// Images - Trois Café
import logoTroisCafe from "assets/images/rewards/trois-cafe/trois-cafe-logo.png";
import rewardsTroisCafe from "assets/images/rewards/trois-cafe/trois-cafe-briefing-img.png";
import troisCafeListing1 from "assets/images/rewards/trois-cafe/trois-cafe-listing1.png";

export const OfferList = [
  new Reward(
    "Regala Skycity Hotel",
    rewardsRegala,
    logoRegala,
    "rewards.regala.briefing",
    "rewards.regala.title",
    "rewards.regala.description",
    "https://www.regala-hotels.com/regala-skycity-hotel/en/home/home.html",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing("grand", regalaListing1, "rewards.regala.listing1.description"),
      new RewardListing("general", regalaListing2, "rewards.regala.listing2.description"),
      new RewardListing("general", regalaListing3, "rewards.regala.listing3.description"),
      new RewardListing("general", regalaListing4, "rewards.regala.listing4.description"),
      new RewardListing("general", regalaListing5, "rewards.regala.listing5.description"),
    ]
  ),
  new Reward(
    "HK Tramways",
    rewardsTramways,
    logoTramways,
    "rewards.tramways.briefing",
    "rewards.tramways.title",
    "rewards.tramways.description",
    "https://www.hktramways.com/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [new RewardListing("top", tramwaysListing1, "rewards.tramways.listing1.description")]
  ),
  new Reward(
    "iClub",
    rewardsIclub,
    logoIclub,
    "rewards.iclub.briefing",
    "rewards.iclub.title",
    "rewards.iclub.description",
    "https://www.iclub-hotels.com/iclub/en/home/home.html",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [new RewardListing("grand", iclubListing1, "rewards.iclub.listing1.description")]
  ),
  new Reward(
    "Regal Hotels",
    rewardsRegal,
    logoRegal,
    "rewards.regal.briefing",
    "rewards.regal.title",
    "rewards.regal.description",
    "https://www.regalhotel.com/regal-hotels-international/en/home/home.html",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing("grand", regalListing1, "rewards.regal.listing1.description"),
      new RewardListing("general", regalListing2, "rewards.regal.listing2.description"),
      new RewardListing("general", regalListing3, "rewards.regal.listing3.description"),
      new RewardListing("general", regalListing4, "rewards.regal.listing4.description"),
      new RewardListing("general", regalListing5, "rewards.regal.listing5.description"),
      new RewardListing("general", regalListing6, "rewards.regal.listing6.description"),
      new RewardListing("general", regalListing7, "rewards.regal.listing7.description"),
      new RewardListing("general", regalListing8, "rewards.regal.listing8.description"),
      new RewardListing("general", regalListing9, "rewards.regal.listing9.description"),
      new RewardListing("general", regalListing10, "rewards.regal.listing10.description"),
      new RewardListing("general", regalListing11, "rewards.regal.listing11.description"),
      new RewardListing("general", regalListing12, "rewards.regal.listing12.description"),
      new RewardListing("general", regalListing13, "rewards.regal.listing13.description"),
      new RewardListing("general", regalListing14, "rewards.regal.listing14.description"),
      new RewardListing("general", regalListing15, "rewards.regal.listing15.description"),
      new RewardListing("general", regalListing16, "rewards.regal.listing16.description"),
      new RewardListing("general", regalListing17, "rewards.regal.listing17.description"),
      new RewardListing("general", regalListing18, "rewards.regal.listing18.description"),
      new RewardListing("general", regalListing19, "rewards.regal.listing19.description"),
      new RewardListing("general", regalListing20, "rewards.regal.listing20.description"),
      new RewardListing("general", regalListing21, "rewards.regal.listing21.description"),
      new RewardListing("general", regalListing22, "rewards.regal.listing22.description"),
      new RewardListing("general", regalListing23, "rewards.regal.listing23.description"),
      new RewardListing("general", regalListing24, "rewards.regal.listing24.description"),
      new RewardListing("general", regalListing25, "rewards.regal.listing25.description"),
      new RewardListing("general", regalListing26, "rewards.regal.listing26.description"),
      new RewardListing("general", regalListing27, "rewards.regal.listing27.description"),
      new RewardListing("general", regalListing28, "rewards.regal.listing28.description"),
      new RewardListing("general", regalListing29, "rewards.regal.listing29.description"),
      new RewardListing("general", regalListing30, "rewards.regal.listing30.description"),
    ]
  ),
  new Reward(
    "Amalfitana Central",
    rewardsAmalfitanaCentral,
    logoAmalfitanaCentral,
    "rewards.amalfitanaCentral.briefing",
    "rewards.amalfitanaCentral.title",
    "rewards.amalfitanaCentral.description",
    "https://www.amalfitana.hk/central",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing(
        "general",
        amalfitanaCentralListing1,
        "rewards.amalfitanaCentral.listing1.description"
      ),
      new RewardListing(
        "general",
        amalfitanaCentralListing2,
        "rewards.amalfitanaCentral.listing2.description"
      ),
    ]
  ),
  new Reward(
    "Amalfitana Repulse Bay",
    rewardsAmalfitanaRepulseBay,
    logoAmalfitanaRepulseBay,
    "rewards.amalfitanaRepulseBay.briefing",
    "rewards.amalfitanaRepulseBay.title",
    "rewards.amalfitanaRepulseBay.description",
    "https://www.amalfitana.hk/repulse-bay",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing(
        "general",
        amalfitanaRepulseBayListing1,
        "rewards.amalfitanaRepulseBay.listing1.description"
      ),
    ]
  ),
  new Reward(
    "BRICK LANE",
    rewardsBRICKLANE,
    logoBRICKLANE,
    "rewards.brickLane.briefing",
    "rewards.brickLane.title",
    "rewards.brickLane.description",
    "https://bricklane.com.hk",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [new RewardListing("general", brickLaneListing1, "rewards.brickLane.listing1.description")]
  ),
  new Reward(
    "CAMPSITE",
    rewardsCAMPSITE,
    logoCAMPSITE,
    "rewards.campsite.briefing",
    "rewards.campsite.title",
    "rewards.campsite.description",
    "https://www.campsite.com.hk/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [new RewardListing("general", campsiteListing1, "rewards.campsite.listing1.description")]
  ),
  new Reward(
    "Carnaby Fair",
    rewardsCarnabyFair,
    logoCarnabyFair,
    "rewards.carnabyFair.briefing",
    "rewards.carnabyFair.title",
    "rewards.carnabyFair.description",
    "http://www.carnabyfair.com/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [new RewardListing("general", carnabyFairListing1, "rewards.carnabyFair.listing1.description")]
  ),
  new Reward(
    "Chart Coffee",
    rewardsChartCoffee,
    logoChartCoffee,
    "rewards.chartCoffee.briefing",
    "rewards.chartCoffee.title",
    "rewards.chartCoffee.description",
    "https://www.instagram.com/chartcoffee/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing("general", chartCoffeeListing1, "rewards.chartCoffee.listing1.description"),
      new RewardListing("general", chartCoffeeListing2, "rewards.chartCoffee.listing2.description"),
    ]
  ),
  new Reward(
    "Check Check Cin",
    rewardsCheckCheckCin,
    logoCheckCheckCin,
    "rewards.checkCheckCin.briefing",
    "rewards.checkCheckCin.title",
    "rewards.checkCheckCin.description",
    "http://www.checkcheckcin.com/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing(
        "general",
        checkCheckCinListing1,
        "rewards.checkCheckCin.listing1.description"
      ),
      new RewardListing(
        "general",
        checkCheckCinListing2,
        "rewards.checkCheckCin.listing2.description"
      ),
      new RewardListing(
        "general",
        checkCheckCinListing3,
        "rewards.checkCheckCin.listing3.description"
      ),
    ]
  ),
  new Reward(
    "Cookieism",
    rewardsCookieism,
    logoCookieism,
    "rewards.cookieism.briefing",
    "rewards.cookieism.title",
    "rewards.cookieism.description",
    "http://www.cookieism.com.hk/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [new RewardListing("general", cookieismListing1, "rewards.cookieism.listing1.description")]
  ),
  new Reward(
    "Faye Hong Kong",
    rewardsFayeHongKong,
    logoFayeHongKong,
    "rewards.fayeHongKong.briefing",
    "rewards.fayeHongKong.title",
    "rewards.fayeHongKong.description",
    "https://www.instagram.com/faye.hkg/?hl=en",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing(
        "general",
        fayeHongKongListing1,
        "rewards.fayeHongKong.listing1.description"
      ),
    ]
  ),
  new Reward(
    "foodpandaMall",
    rewardsFoodpandaMall,
    logoFoodpandaMall,
    "rewards.foodpandaMall.briefing",
    "rewards.foodpandaMall.title",
    "rewards.foodpandaMall.description",
    "https://www.regala-hotels.com/regala-skycity-hotel/en/home/home.html",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing(
        "general",
        foodpandaMallListing1,
        "rewards.foodpandaMall.listing1.description"
      ),
    ]
  ),
  new Reward(
    "Fratelli",
    rewardsFratelli,
    logoFratelli,
    "rewards.fratelli.briefing",
    "rewards.fratelli.title",
    "rewards.fratelli.description",
    "https://www.fratelli.hk/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [new RewardListing("general", fratelliListing1, "rewards.fratelli.listing1.description")]
  ),
  new Reward(
    "KIBO",
    rewardsKIBO,
    logoKIBO,
    "rewards.KIBO.briefing",
    "rewards.KIBO.title",
    "rewards.KIBO.description",
    "https://kibo.eco/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing("general", kiboListing1, "rewards.KIBO.listing1.description"),
      new RewardListing("general", kiboListing2, "rewards.KIBO.listing2.description"),
      new RewardListing("general", kiboListing3, "rewards.KIBO.listing3.description"),
    ]
  ),
  new Reward(
    "LAB EAT",
    rewardsLABEAT,
    logoLABEAT,
    "rewards.LABEAT.briefing",
    "rewards.LABEAT.title",
    "rewards.LABEAT.description",
    "https://www.openrice.com/en/hongkong/r-lab-eat-restaurant-bar-mong-kok-western-seafood-r572152",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing("general", labEatListing1, "rewards.LABEAT.listing1.description"),
      new RewardListing("general", labEatListing2, "rewards.LABEAT.listing2.description"),
    ]
  ),
  new Reward(
    "LITE Salad Bar",
    rewardsLITESaladBar,
    logoLITESaladBar,
    "rewards.LITESaladBar.briefing",
    "rewards.LITESaladBar.title",
    "rewards.LITESaladBar.description",
    "https://www.openrice.com/zh/hongkong/r-lite-%E4%B8%AD%E7%92%B0-%E8%A5%BF%E5%BC%8F-%E6%B2%99%E5%BE%8B-r745269",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing(
        "general",
        liteSaladBarListing1,
        "rewards.LITESaladBar.listing1.description"
      ),
      new RewardListing(
        "general",
        liteSaladBarListing2,
        "rewards.LITESaladBar.listing2.description"
      ),
    ]
  ),
  new Reward(
    "Maison Kayser",
    rewardsMaisonKayser,
    logoMaisonKayser,
    "rewards.maisonKayser.briefing",
    "rewards.maisonKayser.title",
    "rewards.maisonKayser.description",
    "https://www.maison-kayser.com.hk/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing(
        "general",
        maisonKayserListing1,
        "rewards.maisonKayser.listing1.description"
      ),
    ]
  ),
  new Reward(
    "Mammy Pancake",
    rewardsMammyPancake,
    logoMammyPancake,
    "rewards.mammyPancake.briefing",
    "rewards.mammyPancake.title",
    "rewards.mammyPancake.description",
    "http://www.mammypancake.com/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing(
        "general",
        mammyPancakeListing1,
        "rewards.mammyPancake.listing1.description"
      ),
      new RewardListing(
        "general",
        mammyPancakeListing2,
        "rewards.mammyPancake.listing2.description"
      ),
    ]
  ),
  new Reward(
    "Mezzanine Makers",
    rewardsMezzanineMakers,
    logoMezzanineMakers,
    "rewards.mezzanineMakers.briefing",
    "rewards.mezzanineMakers.title",
    "rewards.mezzanineMakers.description",
    "https://www.mezzaninemakers.hk",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [
      new RewardListing(
        "general",
        mezzanineMakersListing1,
        "rewards.mezzanineMakers.listing1.description"
      ),
      new RewardListing(
        "general",
        mezzanineMakersListing2,
        "rewards.mezzanineMakers.listing2.description"
      ),
    ]
  ),
  new Reward(
    "NODI Coffee",
    rewardsNODICoffee,
    logoNODICoffee,
    "rewards.NODICoffee.briefing",
    "rewards.NODICoffee.title",
    "rewards.NODICoffee.description",
    "https://nodicoffee.com/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [new RewardListing("general", nodiCoffeeListing1, "rewards.NODICoffee.listing1.description")]
  ),
  new Reward(
    "pandamart",
    rewardsPandamart,
    logoPandamart,
    "rewards.pandamart.briefing",
    "rewards.pandamart.title",
    "rewards.pandamart.description",
    "https://www.foodpanda.hk/darkstore/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [new RewardListing("general", pandamartListing1, "rewards.pandamart.listing1.description")]
  ),
  new Reward(
    "RiceOnly",
    rewardsRiceOnly,
    logoRiceOnly,
    "rewards.riceOnly.briefing",
    "rewards.riceOnly.title",
    "rewards.riceOnly.description",
    "https://www.instagram.com/riceonlyhk/",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [new RewardListing("general", riceOnlyListing1, "rewards.riceOnly.listing1.description")]
  ),
  new Reward(
    "Trois Café",
    rewardsTroisCafe,
    logoTroisCafe,
    "rewards.troisCafe.briefing",
    "rewards.troisCafe.title",
    "rewards.troisCafe.description",
    "https://www.regala-hotels.com/regala-skycity-hotel/en/home/home.html",
    "https://hop.dttd.app/k9bRlWWZvAb",
    [new RewardListing("general", troisCafeListing1, "rewards.troisCafe.listing1.description")]
  ),
];

export default OfferList;
