/* eslint-disable no-unused-vars */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useEffect, useState, Fragment } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import TrackedExtLink from "dttd/components/TrackedExtLink";

import { MainContext } from "contexts/MainContext";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Link from "@material-ui/core/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { useTranslation } from "react-i18next";

import { FooterDesktop } from "./FooterDesktop";
import { FooterMobile } from "./FooterMobile";

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

import { log_event_analytics } from "dttd/functions/log_event_analytics";

// import TranslateIcon from "@mui/icons-material/Translate";

// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";

function DefaultFooter({ content }) {
  const mainContext = useContext(MainContext);

  //const { brand, socials, menus, copyright } = content;
  const { socials, copyright, termsConditions, privacyPolicy } = content;

  const [mobileView, setMobileView] = useState(false);

  //const { t } = useTranslation("footer");
  const { i18n } = useTranslation();
  //const [lng, setLng] = useState("en");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    function displayMobileFooter() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileFooter);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileFooter();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileFooter);
  }, []);

  // const handleLanguageChange = (event) => {
  //   //setLng(event.target.value);
  //   changeLanguage(event.target.value);
  //   mainContext.setLanguage(event.target.value);
  // };

  const changeLanguageTo = (lng) => {
    log_event_analytics(mainContext, "change_language", { language_to: lng });
    mainContext.setLanguage(lng);
  };

  useEffect(() => {
    changeLanguage(mainContext.language);
  }, [mainContext.language]);

  return (
    <MKBox
      component="footer"
      sx={{
        //backgroundColor: "#0E1E17",
        //color: "white",
        backroundColor: "transparent !important",
        //color: "#737C78",
      }}
    >
      <Fragment>
        {mobileView && <FooterMobile content={content} changeLanguageHandler={changeLanguageTo} />}
        {!mobileView && (
          <FooterDesktop content={content} changeLanguageHandler={changeLanguageTo} />
        )}
      </Fragment>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
