import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { MainProvider } from "contexts/MainContext";
import { Amplify, Analytics, AWSKinesisProvider } from "aws-amplify";
import awsExports from "./aws-exports";

import "./i18n";

Amplify.configure(awsExports);
Analytics.addPluggable(new AWSKinesisProvider());

Analytics.configure({
  AWSKinesis: {
    // OPTIONAL -  Amazon Kinesis service region
    region: awsExports.aws_project_region,
  },
});

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <MainProvider>
      <App />
    </MainProvider>
  </BrowserRouter>
);
