import { useState, useEffect, useContext, Fragment } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Stack from "@mui/material/Stack";
//import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import { revealBgStyle } from "./revealBgStyle";

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

import DisplayPerks from "./sections/DisplayPerks";

import DefaultFooter from "dttd/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

import { useSearchParams } from "react-router-dom";
import { MainContext } from "contexts/MainContext";
import { log_event_analytics } from "dttd/functions/log_event_analytics";

// Import Images
import logoImage from "assets/images/dttd-logo-for-bg.png";
import MKTypography from "components/MKTypography";
import is_valid_uuid from "dttd/functions/is_valid_uuid";

function MerchantOffers() {
  // eslint-disable-next-line no-unused-vars
  const [mobileView, setMobileView] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const mainContext = useContext(MainContext);
  const [showPerks, setShowPerks] = useState(false);

  useEffect(() => {
    // A function that sets the display state for Mobile view or not
    function displayMobileView() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileView);

    // Call the displayMobileView function to set the state with the initial value.
    displayMobileView();

    const persona_id = searchParams.get("persona_id");
    if (persona_id) {
      const is_uuid = is_valid_uuid(persona_id);
      if (is_uuid) {
        setShowPerks(true);
        mainContext.setPersona_id(persona_id);
        log_event_analytics(mainContext, "persona_perk_reveal", {
          location: mainContext.pathname,
          persona_id: mainContext.persona_id,
        });
      } else {
        setShowPerks(false);
      }
    } else {
      setShowPerks(false);
    }

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileView);
  }, []);

  return (
    <>
      <MKBox sx={revealBgStyle}>
        <Container>
          <Grid item xs={12} md={12} lg={12} ml={2} mt={2}>
            <img src={logoImage} alt="DOTTED" width="88" height="28" />
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            md={12}
            lg={12}
            mx="auto"
            textAlign="center"
          >
            {showPerks ? (
              <DisplayPerks />
            ) : (
              <Grid item xs={12} md={12} lg={12}>
                <MKTypography>Sorry, we could not find any offers for you.</MKTypography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MKBox>
              <DefaultFooter content={footerRoutes} />
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default MerchantOffers;
