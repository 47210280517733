import { Fragment } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import { useTranslation } from "react-i18next";

import MKTypography from "components/MKTypography";

import PerkDetail from "./PerkDetail";

// Perks
import { PerkList } from "pages/PerksReveal/perks/PerkList";

function DisplayPerks() {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Grid item xs={12} md={12} lg={12} textAlign="center" justifyContent="center">
        <MKTypography variant="h4" fontWeight="bold">
          {t("perks.title")}
        </MKTypography>
      </Grid>
      <Grid item xs={12} md={12} lg={12} textAlign="center" justifyContent="center">
        <MKTypography variant="caption" fontWeight="bold">
          {t("perks.description")}
        </MKTypography>
      </Grid>
      {PerkList.map((perk, index) => {
        return (
          <Grid item xs={12} md={12} lg={12} key={`perk-${index}`}>
            <Stack direction="column" spacing={1}>
              <PerkDetail idx={index} perk={perk} />
            </Stack>
          </Grid>
        );
      })}
    </Fragment>
  );
}

export default DisplayPerks;
